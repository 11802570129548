/* 공통 태블릿 스타일 */

@media screen and (max-width: 767px) { /**/
 /* Header */
header { width: 100vw; height: 60px; } 
.header-content { width: calc(100vw - 48px); height: 68px; } 
.header-logo { font-size: 16px; } 

/* Button */
.button-secondary { padding: 0; font-size: 14px; } 

.button-fab { bottom: 36px; right: 24px; width: 50px; height: 50px; } 

/* Container */
.maintenance-container p { margin-top: 24px; font-size: 14px; } 

/* Footer */
footer { height: 250px; } 
footer p:first-child { font-size: 12px; text-align: center; line-height: 16px; width: calc(100vw - 40px); } 
footer p:first-child a { font-size: 12px; } 
footer p:nth-child(2) { font-size: 14px; margin: 36px 0 48px; width: calc(100vw - 40px); text-align: center; line-height: 16px; } 
footer p:last-child { font-size: 12px; width: calc(100vw - 40px); line-height: 16px; } 
footer p:last-child { font-size: 12px; text-align: center; line-height: 24px; width: calc(100vw - 40px); } 

/* Drawer */
.drawer-background { position: fixed; width: 100vw; height: 100vh; height: var(--vh); background: rgba(14, 14, 14, 0.8); z-index: 999; top: 0; left: 0; display: flex; justify-content: flex-end; } 
.drawer-content { width: 240px; height: calc(100vh - 28px); height: calc(var(--vh) - 28px); background-color: #252628; padding: 28px 30px 0 36px; } 
.drawer-logo { font-size: 18px; text-align: center; font-family: "Russo One"; margin-bottom: 48px; } 
.drawer-nav-group { height: calc(100% - 297px); display: flex; flex-direction: column; } 
.drawer-nav-group a { width: 100%; height: 40px; margin-bottom: 24px; text-align: center; display: flex; align-items: center; justify-content: center; font-size: 14px; } 
.drawer-nav-group a.active { background-color: #435160; font-weight: 700; } 
.drawer-footer { display: flex; flex-direction: column; align-items: center; } 
.drawer-footer .drawer-footer-logo { mix-blend-mode: luminosity; margin-bottom: 60px; } 
.drawer-footer-group { width: 100%; display: flex; align-items: center; justify-content: space-between; border-top: 1px solid #343434; height: 90px; } 
.drawer-footer-left button:first-child { margin-right: 24px; position: relative; } 
.drawer-footer-left button:first-child::after { position: absolute; content: ''; width: 0.5px; height: 16px; background-color: rgba(255, 255, 255, 0.4); top: 50%; right: -12px; transform: translateY(-50%); } 
 }