/* 일반 PC 스타일 */

/* 소개 */
.about-background { width: 100vw; height: 100vh; height: var(--vh); position: relative; } 
.about-background video { width: 100vw; height: 100vh; height: var(--vh); object-fit: cover; } 
.about-background p { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 76px; font-weight: 900; line-height: 91px; text-align: center; white-space: pre-line; width: 100%; animation: fadeInOut 5s ease-in-out infinite; } 
.about-background .image-animation { position: absolute; bottom: 40px; left: 50%; transform: translateX(-50%); } 

.about-introduce { padding: 172px 0; background: linear-gradient(180deg, rgba(18, 40, 66, 0.1) 0%, rgba(132, 170, 192, 0.1) 50%, rgba(4, 5, 6, 0.1) 100%); display: flex; flex-direction: column; align-items: center; } 
.about-introduce-top { width: 1120px; display: flex; justify-content: space-between; position: relative; } 
.about-introduce-top .about-introduce-background { position: absolute; top: -58px; left: 50%; transform: translateX(-50%); z-index: 100; width: 100vw; } 
.about-introduce-left { position: relative; z-index: 200; } 
.about-introduce-right { position: relative; z-index: 200; width: 580px; } 
.about-introduce-job-title { font-size: 24px; font-weight: 600; background:linear-gradient(90deg, #7EC8F2 0%, #565CDE 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; background-clip: text; color: transparent; margin-bottom: 32px; } 
.about-introduce-name { font-size: 48px; font-weight: 800; margin-bottom: 56px; } 
.about-introduce-name span { font-size: 48px; font-weight: 800; margin-left: 52px; position: relative; } 
.about-introduce-name span::before { position: absolute; content: ''; width: 1px; height: 40px; background-color: #39383e; top: 50%; left: -24px; transform: translateY(-50%); } 
.about-introduce-description { font-size: 20px; white-space: pre-line; line-height: 24px; margin-bottom: 56px; } 
.about-introduce-keyword-group { display: inline-flex; align-items: center; height: 60px; border: 1px solid rgba(255, 255, 255, 0.2); border-radius: 10px; padding: 0 20px; } 
.about-introduce-keyword-group span { font-weight: 700; margin-right: 24px; } 
.about-introduce-keywords { display: flex; align-items: center; } 
.about-introduce-keyword { background-color: #67637b; padding: 10px 16px; border-radius: 50px; } 
.about-introduce-keyword p { font-weight: 700; } 
.about-introduce-keyword:not(:last-child) { margin-right: 12px; } 
.about-introduce-button-group { width: 1120px; display: flex; align-items: center; justify-content: space-between; margin-top: 68px; } 
.about-introduce-button-group button { width: calc(50% - 10px); } 

.about-contents-profile { width: 100vw; display: flex; justify-content: center; background: linear-gradient(180deg, rgba(4, 5, 6, 0.1) 0%, rgba(211, 239, 255, 0.1) 20%, rgba(229, 243, 251, 0.1) 40%, rgba(248, 248, 248, 0.1) 60%); padding: 56px 0 132px; } 
.about-contents-profile-content { width: 1120px; } 
.about-title { text-align: center; font-size: 42px; font-family: 'Russo One'; margin-bottom: 72px; } 
.about-contents-profile-content-wrapper { width: 1120px; display: flex; align-items: center; flex-wrap: wrap; justify-content: space-between; } 
.about-contents-profile-container { width: calc(33.3% - 73.5px); height: 120px; background: rgba(62, 66, 83, 0.3); margin-bottom: 32px; border-radius: 10px; box-shadow: 0px 0px 10px rgba(28, 31, 44, 0.4); display: flex; align-items: center; padding: 0 28px; } 
.about-contents-profile-container.active { background-color: #3F414B; cursor: pointer; } 
.about-contents-profile-container-left { margin-right: 20px; width: 60px; height: 60px; display: flex; align-items: center; justify-content: center; background: rgba(92, 96, 110, 0.8); box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25); border-radius: 50%; } 
.about-contents-profile-container-right p:first-child { color: #717689; margin-bottom: 12px; } 
.about-contents-profile-container-right p:last-child { font-size: 18px; font-weight: 700; } 
.about-contents-profile-center-text { width: 100%; text-align: center; font-weight: 700; font-size: 18px; } 

.about-title-line { display: flex; align-items: flex-end; justify-content: space-between; margin-bottom: 32px; } 
.about-title-line span { font-size: 24px; font-weight: 700; } 
.about-title-line a { text-decoration: underline; color: #dfdfdf; } 
.about-title-line a:hover { color: #C9DDEF; } 
.about-work-experience-wrapper { height: 128px; display: flex; align-items: center; justify-content: space-between; padding: 0 32px 0 20px; } 
.about-work-experience-wrapper:not(:last-child) { border-bottom: 1px solid #484653; } 
.about-work-experience-left { display: flex; align-items: center; } 
.about-work-experience-left .about-work-logo-cover { width: 160px; margin-right: 44px; display: flex; align-items: center; justify-content: center; } 
.about-work-experience-orgniazation p:first-child { font-size: 20px; font-weight: 700; margin-bottom: 16px; } 
.about-work-experience-orgniazation p:last-child { color: #8b93b1; } 
.about-work-experience-orgniazation p:last-child span { font-weight: 700; color: #8b93b1; } 
.about-work-experience-right p:first-child { font-size: 20px; font-weight: 700; text-align: right; margin-bottom: 16px; } 
.about-work-experience-right p:last-child { color: #8b93b1; text-align: right; } 

.about-skill-set { width: 100vw; display: flex; align-items: center; flex-direction: column; padding-top: 56px; background: linear-gradient(180deg, rgba(248, 248, 248, 0.1) 0%, rgba(97, 124, 139, 0.1) 20%, rgba(42, 121, 168, 0.1) 47.5%, rgba(32, 20, 111, 0.1) 100%); } 
.about-skill-button-group { width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 36px 0; background-color: rgba(67, 83, 93, 0.1); } 
.about-skill-button-group-top { width: 884px; margin-bottom: 32px; display: flex; align-items: center; justify-content: space-between; } 
.about-skill-button-group-description { width: 1120px; color: #9eabc6; text-align: center; line-height: 24px; } 
.about-skill-set-group { width: 100vw; padding: 72px 0 64px; background-image: url('../assets/images/about-skill-background.svg'); background-size: cover; display: flex; align-items: center; flex-direction: column; } 
.about-skill-set-box { width: 836px; padding: 32px 24px 0; background: linear-gradient(180deg, rgba(55, 57, 64, 0.8) 0%, rgba(49, 58, 72, 0.8) 100%); border-radius: 20px; box-shadow: 0px 0px 2px #FFFFFF; margin-bottom: 80px; display: flex; flex-direction: column; align-items: center; } 
.about-skill-set-title { text-align: center; font-size: 20px; font-weight: 700; position: relative; } 
.about-skill-set-title::after { position: absolute; content: ''; width: 816px; height: 1px; background-color: #525662; bottom: -32px; left: 50%; transform: translateX(-50%); } 
.about-skills-set { width: 816px; display: flex; align-items: center; justify-content: center; margin-top: 64px; } 
.about-skill-cover { width: 100%; display: flex; flex-wrap: wrap; } 
.about-skill { width: 20%; display: flex; align-items: center; flex-direction: column; margin-bottom: 48px; } 
.about-skill p { font-weight: 700; margin-top: 12px; } 
.about-skill-divider { width: 100%; height: 1px; background-color: #474a58; margin-top: -8px; margin-bottom: 36px; } 
.about-skill-recent-date { width: 860px; color: #bfc6cd; text-align: right; margin-bottom: 80px; } 
.about-skill-month-box { width: 888px; margin-left: 180px; background: linear-gradient(90deg, rgba(62, 66, 83, 0.09) -20.95%, rgba(255, 255, 255, 0.09) 104.11%); border-radius: 0px 20px 20px 0px; border-left: 3px solid #636e8c; padding-top: 32px; position: relative; margin-bottom: 20px; } 
.about-skill-month-each-line { margin-bottom: 36px; display: flex; align-items: center; position: relative; } 
.about-skill-month-name { width: 180px; position: absolute; left: -207px; text-align: right; font-weight: 700; } 
.about-skill-colored { height: 16px; background: linear-gradient(90deg, #636E8C 0%, #FFFFFF 100%); border-radius: 0px 10px 10px 0px; margin-right: 16px; } 
.about-skill-month-date { font-size: 14px; font-weight: 500; } 
.about-skill-primary-tag { position: absolute; right: 0; top: -5px; height: 28px; background-color: #db7238; display: flex; align-items: center; justify-content: center; border-radius: 5px 0 0 5px; width: 115px; z-index: 200; } 
.about-skill-primary-tag p { font-weight: 700; } 
.about-skill-secondary-tag { position: absolute; right: 0; top: -5px; height: 28px; background-color: #636E8C; display: flex; align-items: center; justify-content: center; border-radius: 5px 0 0 5px; width: 115px; z-index: 200; } 
.about-skill-secondary-tag p { font-weight: 700; } 
.about-skill-arrow-line { position: absolute; width: 0.5px; right: 56px; top: 39px; background-image: linear-gradient(180deg, #7C7C7C, #7C7C7C 75%, transparent 75%, transparent 100%); background-size: 20px 9px; border: none; z-index: 100; } 
.about-skill-arrow-triangle-top { position: absolute; width: 10px; height: 10px; top: 39px; right: 51px; border-radius: 50%; background-color: #7C7C7C; z-index: 200; } 
.about-skill-arrow-triangle-down { position: absolute; width: 10px; height: 10px; bottom: 39px; right: 51px; border-radius: 50%; background-color: #7C7C7C; z-index: 200; } 

.about-project { padding-top: 80px; background: linear-gradient(180deg, rgba(32, 20, 111, 0.1) 0%, rgba(42, 121, 168, 0.1) 14%, rgba(229, 243, 251, 0.1) 48%, rgba(248, 248, 248, 0.1) 75%); display: flex; flex-direction: column; align-items: center; } 
.about-project-notice-line { width: 100vw; height: 72px; background-color: rgba(67, 83, 93, 0.1); display: flex; justify-content: center; align-items: center; } 
.about-project-notice-content { width: 1120px; display: flex; justify-content: space-between; } 
.about-project-notice-content a { color: #dfdfdf; text-decoration: underline; } 
.about-project-notice-content a:hover { color: #C9DDEF; } 
.about-project-background { width: 100vw; } 
.about-project-group { width: 1118px; padding: 72px 0 28px; } 
.about-project-box { width: calc(100% - 44px); min-height: 300px; display: flex; align-items: center; justify-content: space-between; background: rgba(0, 0, 0, 0.2); border: 1px solid #8494B2; box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5); border-radius: 20px; margin-bottom: 80px; padding: 8px 36px 8px 8px; } 
.about-project-box img { border-radius: 12px 0 0 12px; } 
.about-project-box-right { width: calc(100% - 433px); } 
.about-project-title { font-size: 20px; font-weight: 700; margin-bottom: 20px; } 
.about-project-tag-line { display: flex; align-items: center; margin-bottom: 24px; } 
.about-project-each-tag { padding: 4px 12px; border-radius: 5px; } 
.about-project-each-tag:not(:last-child) { margin-right: 12px; } 
.about-project-each-tag p { font-weight: 600; font-size: 14px; } 
.about-project-contribution { color: #70d2fc; margin-bottom: 20px; line-height: 20px; } 
.about-project-contribution span { color: #70d2fc; font-weight: 700; } 
.about-project-description { line-height: 24px; word-break: break-all; } 