/* 공통 PC 스타일 */

/* Font */
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css"); /* Pretendard */
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap"); /* Russo One */

/* Default */
:root { --vh: 100%; } 

* { padding: 0; margin: 0; } 
html { height: 100vh; height: var(--vh); } 
body { background-color: #1B1A20; font-family: "Pretendard", sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; width: 100vw; height: 100vh; height: var(--vh); position: relative; display: flex; justify-content: center; } 
p, span { line-height: 1; font-weight: 400; color: white; } 
a { text-decoration: none; color: white; } 
select { -webkit-appearance: none; -moz-appearance: none; appearance: none; } 

/* Loading */
.loading-container { width: 100%; height: 100vh; height: var(--vh); display: flex; align-items: center; justify-content: center; } 

/* Header */
header { width: 100vw; height: 100px; background-color: transparent; position: fixed; top: 0; left: 0; z-index: 900; display: flex; justify-content: center; transition: background-color 300ms ease-in-out; } 
header.active { background-color: rgba(23, 23, 23, 0.9); box-shadow: 0px 2px 4px rgba(125, 125, 125, 0.25); } 
.header-content { width: 1120px; height: 100px; display: flex; align-items: center; justify-content: space-between; } 
.header-logo { font-family: 'Russo One'; font-size: 20px; } 
.header-nav-group { width: 360px; display: flex; align-items: center; justify-content: space-between; } 
.header-nav-group a.active { color: #BBDBED; font-weight: 700; } 
.header-nav-group a:hover { color: #BBDBED; font-weight: 700; } 
.header-flag-group { height: 28px; position: relative; } 
.header-flag-group button:first-child { margin-right: 36px; } 
.header-flag-group button:first-child::after { position: absolute; content: ''; width: 1px; height: 20px; background-color: rgba(255, 255, 255, 0.4); top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: default; } 

/* Button */
.button-icon { border: 0; padding: 0; background: none; cursor: pointer; } 
.button-icon img { display: block; } 

.button-secondary { border: 0; background-color: #2B3141; padding: 31px; color: white; font-size: 18px; font-weight: 700; border-radius: 10px; cursor: pointer; } 
.button-secondary:hover { background-color: #313744; } 

.button-primary { border: 0; background-color: #3A4B88; width: 200px; height: 60px; display: flex; align-items: center; justify-content: center; color: white; font-size: 18px; font-weight: 700; border-radius: 10px; } 

.button-outline { border: 0; background-color: transparent; width: 200px; height: 60px; display: flex; align-items: center; justify-content: center; color: #6B7383; font-size: 18px; font-weight: 700; border-radius: 10px; border: 1px solid #6B7383; cursor: pointer; } 
.button-outline:hover { background-color: #3a4b88; color: white; border: 0; } 

.button-fab { position: fixed; bottom: 80px; right: 40px; width: 60px; height: 60px; background-color: #474747; border-radius: 50%; cursor: pointer; border: none; z-index: 800; } 
.button-fab:hover { background-color: #414040; } 

/* Container */
.maintenance-container { width: 100vw; height: 100vh; height: var(--vh); display: flex; align-items: center; justify-content: center; flex-direction: column; } 
.maintenance-container p { margin-top: 48px; font-size: 18px; } 
main { width: 100vw; position: relative; } 

/* Footer */
footer { width: 100vw; height: 400px; background: linear-gradient(180deg, #172834 -22.37%, #1B1A20 103.75%); display: flex; align-items: center; justify-content: center; flex-direction: column; } 
footer p:first-child { font-size: 18px; } 
footer p:first-child a { font-size: 18px; font-weight: 700; color: #6ee0ef; text-decoration: underline; } 
footer p:first-child a:hover { color: #3AB9CA; } 
footer p:nth-child(2) { font-size: 20px; font-weight: 700; margin: 40px 0 88px; } 
footer p:last-child { color: #abb1be; font-size: 14px; } 
footer p:last-child span { color: #abb1be; font-size: 14px; font-weight: 100; } 

/* Drawer */
.drawer-background { position: fixed; width: 100vw; height: 100vh; height: var(--vh); background: rgba(14, 14, 14, 0.8); z-index: 999; top: 0; left: 0; display: flex; justify-content: flex-end; } 
.drawer-content { width: 240px; height: calc(100vh - 28px); height: calc(var(--vh) - 28px); background-color: #252628; padding: 28px 30px 0 36px; } 
.drawer-logo { font-size: 18px; text-align: center; font-family: "Russo One"; margin-bottom: 48px; } 
.drawer-nav-group { height: calc(100% - 297px); display: flex; flex-direction: column; } 
.drawer-nav-group a { width: 100%; height: 40px; margin-bottom: 24px; text-align: center; display: flex; align-items: center; justify-content: center; font-size: 14px; } 
.drawer-nav-group a.active { background-color: #435160; font-weight: 700; } 
.drawer-footer { display: flex; flex-direction: column; align-items: center; } 
.drawer-footer .drawer-footer-logo { mix-blend-mode: luminosity; margin-bottom: 60px; } 
.drawer-footer-group { width: 100%; display: flex; align-items: center; justify-content: space-between; border-top: 1px solid #343434; height: 90px; } 
.drawer-footer-left button:first-child { margin-right: 24px; position: relative; } 
.drawer-footer-left button:first-child::after { position: absolute; content: ''; width: 0.5px; height: 16px; background-color: rgba(255, 255, 255, 0.4); top: 50%; right: -12px; transform: translateY(-50%); } 