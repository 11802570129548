/* 일반 태블릿 스타일 */

@media screen and (min-width: 768px) and (max-width: 1119px) { /**/
 /* 소개 */
.about-background p { font-size: 44px; line-height: 60px; } 
.about-background .image-animation { bottom: 36px; } 

.about-introduce { padding: 80px 48px; } 
.about-introduce-top { width: calc(100vw - 96px); } 
.about-introduce-top .about-introduce-background { top: -80px; } 
.about-introduce-left { display: flex; flex-direction: column; align-items: center; } 
.about-introduce-right { width: calc(100% - 256px); } 
.about-introduce-job-title { font-size: 18px; margin-bottom: 24px; } 
.about-introduce-name { font-size: 36px; margin-bottom: 48px; } 
.about-introduce-name span { font-size: 36px; } 
.about-introduce-name span::before { height: 36px; } 
.about-introduce-description { font-size: 16px; line-height: 22px; margin-bottom: 36px; } 
.about-introduce-keyword-group { height: 50px; padding: 0 16px; } 
.about-introduce-keyword-group span { font-size: 14px; margin-right: 20px; } 
.about-introduce-keyword { padding: 7px 12px; } 
.about-introduce-keyword p { font-size: 14px; } 
.about-introduce-keyword:not(:last-child) { margin-right: 8px; } 
.about-introduce-button-group { width: 210px; margin-top: 40px; display: flex; flex-direction: column; } 
.about-introduce-button-group button { width: 100%; } 
.about-introduce-button-group button:not(:last-child) { margin-bottom: 12px; } 

.about-contents-profile { width: calc(100vw - 96px); padding: 64px 48px 88px; } 
.about-contents-profile-content { width: 100vw; } 
.about-title { font-size: 28px; margin-bottom: 48px; } 
.about-contents-profile-content-wrapper { width: 100%; flex-wrap: wrap; } 
.about-contents-profile-container { width: calc(50% - 60px); height: 110px; margin-bottom: 24px; padding: 0 24px; } 
.about-contents-profile-container-right p:first-child { font-size: 14px; } 
.about-contents-profile-container-right p:last-child { font-size: 16px; } 

.about-title-line { margin-bottom: 24px; } 
.about-title-line span { font-size: 18px; } 
.about-title-line a { font-size: 14px; } 
.about-work-experience-wrapper { height: 100px; padding: 0; } 
.about-work-experience-left .about-work-logo-cover { width: 130px; margin-right: 28px; } 
.about-work-experience-orgniazation p:first-child { font-size: 14px; margin-bottom: 12px; } 
.about-work-experience-orgniazation p:last-child { font-size: 12px; } 
.about-work-experience-orgniazation p:last-child span { font-size: 12px; } 
.about-work-experience-right p:first-child { font-size: 14px; margin-bottom: 12px; } 
.about-work-experience-right p:last-child { position: relative; font-size: 12px; white-space: pre-line; line-height: 18px; } 

.about-skill-set { padding-top: 44px; } 
.about-skill-button-group { padding: 24px 0; } 
.about-skill-button-group-top { width: 648px; margin-bottom: 24px; } 
.about-skill-button-group-description { width: calc(100vw - 80px); font-size: 14px; } 
.about-skill-set-group { width: 100vw; padding: 52px 0 64px; } 
.about-skill-set-box { width: 648px; padding: 28px 24px 0; margin-bottom: 60px; } 
.about-skill-set-title { font-size: 18px; } 
.about-skill-set-title::after { width: 596px; bottom: -28px; } 
.about-skills-set { width: 648px; margin-top: 56px; } 
.about-skill { width: 25%; margin-bottom: 40px; } 
.about-skill p { font-size: 14px; } 
.about-skill-divider { width: 596px; margin-bottom: 28px; position: relative; left: 50%; transform: translateX(-50%); } 
.about-skill-recent-date { width: calc(100vw - 72px); margin-bottom: 48px; font-size: 14px; padding-right: 20px; } 
.about-skill-month-box { width: calc(100vw - 40px); margin-left: 0; padding: 24px 20px; border-radius: 0; padding-top: 32px; margin-bottom: 16px; border: 0; } 
.about-skill-month-each-line { margin-bottom: 28px; } 
.about-skill-month-name { width: 180px; left: 0; text-align: left; font-size: 12px; top: -16px; } 
.about-skill-colored { height: 5px; margin-right: 0; } 
.about-skill-month-date { width: 120px; text-align: right; font-size: 11px; position: relative; margin-left: 12px; top: 0; font-weight: 200; color: #DCDCDC; } 
.about-skill-primary-tag { top: -7px; height: 20px; width: 80px; } 
.about-skill-primary-tag p { font-size: 12px; } 
.about-skill-secondary-tag { top: -7px; height: 20px; width: 80px; } 
.about-skill-secondary-tag p { font-size: 12px; } 
.about-skill-arrow-line { right: 40px; top: 24px; background-size: 16px 9px; } 
.about-skill-arrow-triangle-top { width: 8px; height: 8px; top: 24px; right: 36.3px; } 
.about-skill-arrow-triangle-down { width: 8px; height: 8px; bottom: 24px; right: 36.3px; } 

.about-project { padding-top: 64px; } 
.about-project-notice-line { height: 64px; } 
.about-project-notice-content { width: calc(100vw - 64px); } 
.about-project-notice-content span { font-size: 14px; } 
.about-project-notice-content a { font-size: 14px; } 
.about-project-group { width: 100vw; padding: 48px 0 20px; } 
.about-project-box { width: calc(100vw - 64px); min-height: 250px; border: 0; border-radius: 0; margin-bottom: 40px; padding: 24px 32px; box-shadow: none; } 
.about-project-box img { border-radius: 5px; } 
.about-project-box-right { width: calc(100% - 312px); } 
.about-project-title { font-size: 18px; margin-bottom: 18px; } 
.about-project-tag-line { margin-bottom: 12px; flex-wrap: wrap; } 
.about-project-each-tag { padding: 4px 8px; margin-bottom: 8px; } 
.about-project-each-tag:not(:last-child) { margin-right: 8px; } 
.about-project-each-tag p { font-size: 12px; } 
.about-project-contribution { font-size: 14px; margin-bottom: 20px; } 
.about-project-contribution span { font-size: 14px; } 
.about-project-description { font-size: 14px; line-height: 20px; } 
 }