/* 일반 모바일 스타일 */

@media screen and (max-width: 767px) { /**/
 /* 소개 */
.about-background p { font-size: 24px; line-height: 36px; } 
.about-background .image-animation { bottom: 28px; } 

.about-introduce { padding: 64px 0 56px; } 
.about-introduce-top { width: 100vw; flex-direction: column; } 
.about-introduce-top .about-introduce-background { top: -160px; } 
.about-introduce-left { width: calc(100vw - 56px); margin-left: 28px; justify-content: center; display: flex; } 
.about-introduce-right { width: calc(100vw - 56px); margin-left: 28px; } 
.about-introduce-job-title { font-size: 14px; margin-bottom: 20px; } 
.about-introduce-name { font-size: 24px; margin-bottom: 36px; } 
.about-introduce-name span { font-size: 24px; margin-left: 36px; } 
.about-introduce-name span::before { height: 28px; left: -18px; } 
.about-introduce-description { font-size: 14px; line-height: 20px; margin-bottom: 28px; margin-top: 32px; } 
.about-introduce-keyword-group { height: auto; padding: 12px 12px 8px; } 
.about-introduce-keyword-group span { font-size: 12px; margin-right: 12px; min-width: 60px; margin-bottom: 8px; } 
.about-introduce-keywords { display: flex; align-items: center; flex-wrap: wrap; } 
.about-introduce-keyword { padding: 7px 12px; margin-bottom: 6px; } 
.about-introduce-keyword p { font-size: 12px; } 
.about-introduce-keyword:not(:last-child) { margin-right: 8px; } 
.about-introduce-button-group { width: calc(100% - 56px); margin-top: 28px; flex-wrap: wrap; margin-left: 28px; } 
.about-introduce-button-group button { width: 100%; height: 50px; display: flex; align-items: center; justify-content: center; font-size: 14px; } 
.about-introduce-button-group button:not(:last-child) { margin-bottom: 12px; } 

.about-contents-profile { padding: 64px 0 80px; } 
.about-contents-profile-content { width: calc(100vw - 56px); } 
.about-title { font-size: 20px; margin-bottom: 32px; } 
.about-contents-profile-content-wrapper { width: 100%; } 
.about-contents-profile-container { width: calc(50% - 8px); margin-bottom: 20px; padding: 0; flex-wrap: wrap; flex-direction: column; justify-content: center; } 
.about-contents-profile-container-left { margin-right: 0; width: 44px; height: 44px; margin-bottom: 12px; } 
.about-contents-profile-container-right p:first-child { margin-bottom: 8px; font-size: 12px; text-align: center; } 
.about-contents-profile-container-right p:last-child { width: 100%; font-size: 12px; text-align: center; white-space: pre-line; word-break: break-all; } 
.about-contents-profile-center-text { font-size: 12px; } 

.about-title-line { margin-bottom: 16px; } 
.about-title-line span { font-size: 16px; } 
.about-title-line a { font-size: 12px; } 
.about-work-experience-wrapper { height: auto; flex-direction: column; flex-wrap: wrap; padding: 20px 0 28px; } 
.about-work-experience-left { width: calc(100vw - 56px); margin-bottom: 24px; display: flex; flex-direction: column; } 
.about-work-experience-left .about-work-logo-cover { width: calc(100vw - 56px); margin-right: 0; margin-bottom: 16px; } 
.about-work-experience-orgniazation p:first-child { width: 100%; font-size: 16px; margin-bottom: 12px; text-align: center; } 
.about-work-experience-orgniazation p:last-child { font-size: 12px; text-align: center; } 
.about-work-experience-orgniazation p:last-child span { width: 100%; font-size: 12px; text-align: center; } 
.about-work-experience-right p:first-child { font-size: 16px; text-align: center; margin-bottom: 12px; line-height: 18px; } 
.about-work-experience-right p:last-child { font-size: 12px; text-align: center; line-height: 16px; } 

.about-skill-set { padding-top: 48px; } 
.about-skill-button-mobile-group { width: 100%; display: flex; justify-content: center; } 
.about-skill-button-mobile-group .select-wrapper { width: 160px; position: relative; } 
.about-skill-button-mobile-group select { width: 100%; padding: 12px 16px; border: 1px solid #6b7383; border-radius: 5px; background-color: transparent; color: #6b7383; font-size: 14px; font-weight: 700; box-sizing: border-box; } 
.select-wrapper::after { content: '▼'; position: absolute; right: 16px; top: 50%; transform: translateY(-50%); pointer-events: none; font-size: 14px; color: #6B7383; } 
.about-skill-button-mobile-group select:focus { outline: none; } 
.about-skill-button-group { width: 100%; padding: 24px 0; } 
.about-skill-button-group-top { width: 100%; margin-bottom: 20px; } 
.about-skill-button-group-description { width: calc(100vw - 40px); font-size: 12px; line-height: 18px; } 
.about-skill-set-group { width: 100vw; padding: 28px 0 36px; } 
.about-skill-set-box { width: calc(100vw - 32px); padding: 24px 16px 0; border-radius: 0; box-shadow: none; margin-bottom: 40px; } 
.about-skill-set-title { font-size: 16px; } 
.about-skill-set-title::after { width: calc(100vw - 32px); bottom: -20px; } 
.about-skills-set { width: calc(100vw - 32px); margin-top: 40px; } 
.about-skill { width: 33.33%; margin-bottom: 32px; } 
.about-skill p { font-size: 12px; } 
.about-skill-divider { margin-top: 0; margin-bottom: 24px; } 
.about-skill-recent-date { width: calc(100vw - 32px); font-size: 12px; margin-bottom: 64px; } 
.about-skill-month-box { width: calc(100vw - 40px); margin-left: 0; padding: 24px 20px; border-radius: 0; padding-top: 32px; margin-bottom: 16px; border: 0; } 
.about-skill-month-each-line { margin-bottom: 28px; } 
.about-skill-month-name { width: 180px; left: 0; text-align: left; font-size: 12px; top: -16px; } 
.about-skill-colored { height: 5px; margin-right: 0; } 
.about-skill-month-date { width: 120px; text-align: right; font-size: 11px; position: relative; margin-left: 12px; top: 0; font-weight: 200; color: #DCDCDC; } 
.about-skill-primary-tag { top: -7px; height: 20px; width: 80px; } 
.about-skill-primary-tag p { font-size: 12px; } 
.about-skill-secondary-tag { top: -7px; height: 20px; width: 80px; } 
.about-skill-secondary-tag p { font-size: 12px; } 
.about-skill-arrow-line { right: 40px; top: 24px; background-size: 16px 9px; } 
.about-skill-arrow-triangle-top { width: 8px; height: 8px; top: 24px; right: 36.3px; } 
.about-skill-arrow-triangle-down { width: 8px; height: 8px; bottom: 24px; right: 36.3px; } 

.about-project { padding-top: 64px; } 
.about-project-notice-line { height: 90px; } 
.about-project-notice-content { width: 100vw; flex-direction: column; text-align: center; } 
.about-project-notice-content span { font-size: 14px; } 
.about-project-notice-content a { font-size: 14px; margin-top: 16px; } 
.about-project-background { width: 100vw; } 
.about-project-group { width: 100vw; padding: 28px 0 36px; } 
.about-project-box { width: calc(100% - 32px); min-height: 300px; border: none; box-shadow: none; border-radius: 0; margin-bottom: 40px; padding: 16px 16px 24px; flex-wrap: wrap; } 
.about-project-box img { border-radius: 5px; } 
.about-project-box-left { width: 100%; display: flex; justify-content: center; } 
.about-project-box-right { width: 100%; } 
.about-project-title { font-size: 16px; margin-bottom: 16px; margin-top: 20px; } 
.about-project-tag-line { flex-wrap: wrap; margin-bottom: 10px; } 
.about-project-each-tag { padding: 4px 6px; margin-bottom: 6px; } 
.about-project-each-tag:not(:last-child) { margin-right: 8px; } 
.about-project-each-tag p { font-size: 12px; } 
.about-project-contribution { font-size: 14px; } 
.about-project-contribution span { font-size: 14px; } 
.about-project-description { font-size: 14px; line-height: 20px; } 
 }