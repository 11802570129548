/* 공통 태블릿 스타일 */

@media screen and (min-width: 768px) and (max-width: 1119px) { /**/
 /* Button */
.button-secondary { padding: 20px; font-size: 16px; } 

.button-primary { width: 150px; height: 48px; font-size: 14px; } 

.button-outline { width: 150px; height: 48px; font-size: 14px; } 

.button-fab { bottom: 48px; right: 36px; width: 52px; height: 52px; } 

/* Header */
header { width: calc(100vw - 72px); padding: 0 36px; height: 80px; } 
.header-content { width: 100%; height: 80px; } 
.header-logo { font-size: 18px; } 

/* Footer */
footer { height: 304px; } 
footer p:first-child { font-size: 16px; } 
footer p:first-child a { font-size: 16px; } 
footer p:nth-child(2) { font-size: 18px; margin: 40px 0 64px; } 
footer p:last-child { font-size: 12px; } 
footer p:last-child span { font-size: 12px; } 

/* Container */
.maintenance-container p { margin-top: 36px; font-size: 16px; } 
 }